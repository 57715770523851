import { default as indexnrigymRIXtMeta } from "/home/runner/work/rsp-space/rsp-space/src/pages/index.vue?macro=true";
export default [
  {
    name: indexnrigymRIXtMeta?.name ?? "index",
    path: indexnrigymRIXtMeta?.path ?? "/",
    meta: indexnrigymRIXtMeta || {},
    alias: indexnrigymRIXtMeta?.alias || [],
    redirect: indexnrigymRIXtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rsp-space/rsp-space/src/pages/index.vue").then(m => m.default || m)
  }
]